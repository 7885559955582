.ant-tree {
  &-switcher {
    svg {
      font-size: 1rem;
    }
  }

  &-indent {
    border-right: 3px solid var(--color-lightergray);
  }

  .ant-tree {
    &-treenode {
      padding: 0;
      display: flex;
      align-items: center;
    }

    &-node-content-wrapper {
      cursor: default;
    }
  }
}

.tree {
  &__option {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;
  }

  &__perc {
    color: var(--color-darkergray);
    height: 3rem;
    line-height: 3rem;
    font-size: 1.5rem;
    padding-right: 1rem;

    span {
      font-size: 1.75rem;
    }
  }

  &__description {
    padding: 0.25rem 0;
    padding-right: 1rem;
  }

  &__title {
    font-weight: 700;

    svg {
      margin-right: 0.5rem;
    }

    .anticon {
      font-size: 1.5rem;
    }

    span {
      font-size: 1.5rem;
    }

    button > span {
      font-size: initial;
    }

    button {
      line-height: 20px;
    }
  }

  &__subtitle {
    font-size: 1.25rem;
  }

  &__actions {
    line-height: 3.5rem;

    .tree__btn {
      &--warning svg {
        opacity: 0.4;
        color: var(--color-secondary);

        &:hover {
          opacity: 1;
          color: var(--color-secondary);
        }
      }
    }

    svg {
      transition: 0.2s all;
      color: var(--color-gray);
      font-size: 1.25rem;
      cursor: pointer;
      margin: 0 0.5rem;

      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

.ownership-tree {
  &__subtitle {
    font-size: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
