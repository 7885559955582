.horizontal-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-size: 14px;

  &--bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
