.ant-card .ant-typography h4 {
  font-weight: 600;
}

.ant-card:hover {
  border-color: var(--color-success);
}

.ant-card.datablock {
  .ant-card-actions .anticon {
    color: var(--color-lightgray);
  }
}

.ant-card.datablock--selected {
  border-color: var(--color-success);
  outline-style: solid;
  outline-color: transparent;
  outline-width: thin;
  .ant-card-actions .anticon {
    color: var(--color-darkergray);
    &:hover {
      color: var(--color-black);
    }
  }
}

.ant-card .ant-tag {
  margin-right: 0;
  max-height: 24px;
}

.ant-card-actions {
  height: 100%;
  border-top: 1px solid var(--color-lightgray);
  border-radius: var(--border-radius-base);
  display: flex;
}

.ant-card-actions > li {
  margin: 8px;
  align-self: center;
}

.ant-card-actions > li {
  div {
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1rem;
  }
}

.ant-card-actions > li:not(:last-child) {
  border-right: none;
}

.ant-card .ant-list-sm .ant-list-item {
  border-bottom: none;
  padding: 0.25rem 0.75rem;
  line-height: 1.4;
  color: var(--color-darkergray);
}

.ant-card h4 {
  line-height: 1.2;
  margin-bottom: 0.5em;
  margin-top: 1em;
  white-space: normal;
}

.ant-card-head {
  min-height: auto;
  border-bottom: none;
  cursor: default;
  .ant-card-head-title {
    font-weight: 600;
    margin-bottom: var(--margin-sm);
    margin-top: var(--margin-md);
    cursor: pointer;
    .edit-card__title {
      line-height: 1.2;
      border-bottom: 2px solid var(--color-lightgray);
      white-space: normal;
      margin-bottom: var(--margin-sm);
      padding-bottom: var(--margin-md);
      color: var(--color-darkergray);
    }
  }
}

.ant-card-body {
  padding: 0 1.5rem;
  height: 100%;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: default;
  .ant-list {
    margin-bottom: 1.5rem;
  }
  .ant-select {
    margin-bottom: var(--margin-md);
  }
  small {
    text-transform: uppercase;
    font-weight: 600;
  }
  textarea.ant-input {
    border: none;
    padding: 0;
    &:focus,
    .ant-input-focused {
      box-shadow: none;
    }
  }
  .ant-typography-disabled {
    cursor: default;
  }
}

.card-simple {
  &:hover {
    border-color: var(--color-white);
  }
  padding: 1.5rem 0 0 0;
}

.ant-card.questionnaire-card {
  .ant-card-actions {
    padding-left: 1rem;

    > :first-child {
      text-align: left;
    }
  }

  margin-bottom: 1.25rem;
  min-height: 250px;
  max-height: 400px;

  &.table {
    min-height: unset;
    max-height: unset;
  }
}

.ant-card.questionnaire-result {
  margin-bottom: 1rem;

  .ant-card-body {
    padding-top: 1.5rem;
  }
}
