.new-service-block h1 {
  margin-bottom: 0.2em;
}

.fs-16 {
  font-size: 16px;
}

.fw-600 {
  font-weight: 600;
}

.page-title h1 {
  margin: 0;
}

.color-darkergray {
  color: var(--color-darkergray);
}

.color-success {
  color: var(--color-success);
}
