.ant-select-selection-placeholder {
  opacity: 0.7;
}

.ant-select-item-option-content {
  span {
    white-space: normal;
  }
}

.storybookSelect {
  .ant-select-selector:not(.ant-select-selector-disabled) {
    border: 2px solid rgb(196, 15, 17);
    color: rgba(196, 15, 17, 1);
    background-color: transparent;
    transition-duration: 0s;
  }

  .ant-select-selection-placeholder {
    font-weight: 600;
    opacity: 1;
  }

  .ant-select-selector:hover:not(.ant-select-selector-disabled) {
    background-color: white;
    border-color: rgba(149, 11, 13, 1);
    color: rgba(149, 11, 13, 1);
  }

  .ant-select-arrow {
    color: rgb(196, 15, 17) !important;
  }

  .ant-select-arrow:hover {
    color: rgb(149, 11, 13) !important;
  }
}
