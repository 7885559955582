.ant-collapse-content {
  background-color: transparent;
  border-top: 1px solid var(--color-gray);
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 1.25rem 0;
}

.ant-collapse-header {
  font-size: 24px;
  font-weight: 800;
  border-bottom: 1px solid var(--color-lightgray);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 20px 0 16px 16px;
  padding-left: 40px;
}

.ant-collapse-header svg {
  fill: var(--color-gray);
}

.ant-collapse-header svg:hover {
  fill: inherit;
}

.ant-collapse-header input {
  min-width: 400px;
}

.ant-collapse-header .ant-collapse-arrow svg {
  fill: var(--color-secondary);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 20px;
  left: 4px;
}

.ant-collapse {
  border: none;
  background-color: transparent;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}
