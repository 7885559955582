.landing-page {
  text-align: center;
  margin-top: 3rem;

  .logo {
    margin: auto;
    max-width: 200px;
  }

  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  .carousel {
    position: relative;

    .carousel-overlay {
      background: rgba(39, 40, 51, 0.6);
      height: 400px;
      margin-top: -406px;

      p {
        padding: 0rem 12rem;
        margin-top: 3rem;
        margin-bottom: 2.5rem;
        color: var(--color-lightestgray);
        line-height: 2rem;
        font-weight: 600;
        font-size: 24px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .text-white {
    color: var(--color-lightestgray);
  }
}
