.domain-page-header > .ant-page-header-heading {
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 5px;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--color-gray);

  .ant-page-header-heading-left {
    width: 50%;
    .ant-page-header-heading-title {
      width: 100%;
    }
  }

  .ant-page-header-heading-extra {
    .ant-btn-link {
      color: var(--color-gray);
    }
    .ant-btn-link:hover {
      color: var(--color-black);
    }
  }
}

.domain-page-header > .ant-page-header-content {
  .sub-chapter__title {
    padding: 16px 0;
  }
}

.sub-chapter__title > .ant-page-header-heading {
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 5px;
  margin-bottom: 1em;

  .ant-page-header-heading-left {
    width: 50%;
    .ant-page-header-heading-title {
      width: 100%;
      font-weight: 400;
      color: var(--color-darkergray);
    }
  }

  .ant-page-header-heading-extra {
    .ant-btn-link {
      color: var(--color-gray);
    }
    .ant-btn-link:hover {
      color: var(--color-black);
    }
  }
}
