.ant-table-thead > tr > th {
  font-weight: 600;
  background: var(--color-lightgray);
}

.ant-table-cell {
  p {
    margin-bottom: 0.25rem;
  }
}
