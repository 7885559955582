.ant-form-item-label > label {
  font-weight: 600;
}

.ant-form-item {
  &.margin-bottom-none {
    margin-bottom: 0;
  }
}

.checkbox-round .ant-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  border: 1px solid var(--color-darkergray);
}
.checkbox-round .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-success);
  border-color: var(--color-success);
}

.ant-card-hoverable:hover .checkbox-round .ant-checkbox,
.ant-card-hoverable:hover .checkbox-round .ant-checkbox-checked::after,
.checkbox-round .ant-checkbox:hover,
.checkbox-round .ant-checkbox-checked::after {
  border-radius: 50%;
  border: 1px solid var(--color-success);
}

.checkbox-round .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--color-success);
}

.radio-custom .ant-radio .ant-radio-inner {
  border-radius: 50%;
  border: 1px solid var(--color-darkergray);
}
.radio-custom .ant-radio.ant-radio-checked .ant-radio-inner {
  background-color: var(--color-success);
  border-color: var(--color-success);
}
.radio-custom .ant-radio.ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-white);
}

.radio-custom .ant-radio:hover .ant-radio-inner {
  border-color: var(--color-success);
}
