.ant-btn {
  text-transform: uppercase;
}

.ant-btn-default {
  background-color: transparent;
  border-color: var(--color-darkergray);
}

.btn-secondary {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-white);
}

.btn-darkred {
  background-color: var(--color-darkred);
  border-color: var(--color-darkred);
  color: var(--color-white);
  padding: 0 2rem;
}

.btn-action {
  color: var(--color-gray);

  &:focus {
    color: var(--color-gray);
  }

  &--disabled {
    &:hover {
      color: var(--color-gray);
    }
  }

  &--warning {
    opacity: 0.4;
    color: var(--color-secondary);

    svg {
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      opacity: 1;
      color: var(--color-secondary);
    }
  }
}

.btn--card-action {
  padding: 0;
  height: 1.5rem;
  border: 0;
}
