.ant-tag {
  background-color: transparent;
  border-radius: 3px;
}

.ant-tag-checkable {
  border: 1px solid var(--color-success);
  background-color: var(--color-white);
}

.ant-tag-checkable:hover {
  border: 2px solid var(--color-success);
}

.ant-tag-checkable-checked {
  background-color: var(--color-success);
}

.tag-group .ant-tag {
  margin-bottom: 0.5rem;
}

.ant-tag.tag-sm {
  font-size: 10px;
  color: var(--color-darkergray);
}

.ant-tag.tag-lg {
  font-size: 14px;
  padding: 0.5rem 1rem;
}

.ant-tag.tag-sm.tag-price {
  padding: 0rem 0.7rem;
  color: var(--color-info);
  border-color: var(--color-info);
  border-width: 1px;
  border-radius: 0px;
}
