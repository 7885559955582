.gdpr-cookie-consent {
  position: fixed;
  bottom: 1.75rem !important;
  right: 1.75rem;
  padding: 1.25rem;
  width: 22.5rem;
  background-color: rgb(255, 255, 255);
  z-index: 12000;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  border-radius: --border-radius-base;
  opacity: 100;
}

.gdpr-cookie-consent-content {
  color: --color-black;
  padding-bottom: 1.25rem;
}
