.container {
  width: 100%;
  margin: auto;
  padding: 0 2rem;
}

.has-shadow {
  box-shadow: var(--shadow);
}

.ant-layout-header .ant-menu {
  background-color: var(--color-lightestgray);
}

.ant-layout-sider-has-trigger {
  padding-bottom: 80px;

  .ant-layout-sider-trigger {
    bottom: 1rem;
    line-height: normal;
  }
}

.inline-flex {
  display: inline-flex;
}

.center {
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ant-space-vertical {
  margin: 1rem auto;
}

.space-vertical-sm {
  margin: 0.75rem auto;
}

.space-vertical-md {
  margin: 1rem auto;
}

.space-vertical-lg {
  margin: 2rem auto;
}

.space-bottom-none {
  margin-bottom: 0 !important;
}

.space-top-none {
  margin-top: 0;
}

.space-bottom-sm {
  margin-bottom: 0.75rem;
}

.space-bottom-md {
  margin-bottom: 1rem;
}

.space-bottom-lg {
  margin-bottom: 2rem;
}

.space-top-xsm {
  margin-top: 0.5rem;
}

.space-left-md {
  margin-left: 1rem;
}

.space-left-sm {
  margin-left: 0.5rem;
}

.padding-xxsm {
  padding: 0.25rem;
}

.padding-right-xxsm {
  padding-right: 0.25rem;
}

.padding-sm {
  padding: 0.75rem;
}

.padding-lg {
  padding: 1.5rem;
}

.padding-none {
  padding: 0;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}
