.logo {
  background-image: url('../img/ci-logo-on-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1rem 1rem 0 1rem;
  height: 55px;

  &--collapsed {
    background-image: url('../img/ci-symbol-on-white.svg');
  }
}

.ant-layout-header {
  padding: 0 2rem;

  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid transparent;
  }

  .ant-btn {
    text-transform: none;
    padding: 0;

    &--back {
      width: 3rem;
      padding-right: 2rem;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }

  .ant-select {
    width: 80px;

    &-selector {
      font-weight: 600;
    }
  }

  li {
    padding: 0 0.75rem;
  }
}

.notification-bell {
  position: relative;
  width: 3rem;

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 1.4rem;
  }

  &--unread {
    color: var(--color-secondary);
  }
}

.ant-menu-vertical {
  border: none;
}

%menu-item-highlight {
  font-weight: bold;
  span {
    svg {
      color: var(--color-secondary);
    }
  }
}

.ant-layout-sider {
  .ant-menu-item {
    transition: all 0.1s ease-in-out;
    &-selected {
      @extend %menu-item-highlight;

      a::before {
        border-left: 3px solid var(--color-secondary);
      }
    }

    &-group {
      &-list {
        li {
          padding: 0 16px;
        }
      }
    }

    &:hover {
      @extend %menu-item-highlight;
    }
  }
}
