.ant-radio-group-small .ant-radio-button-wrapper {
  height: 26px;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}

.ant-radio-group-small .ant-radio-button-wrapper:hover {
  border-color: var(--color-black);
}

.ant-radio-button-wrapper {
  border-left-width: 2.02px;
}

.radio-horizontal {
  width: '100%';

  &__item {
    position: absolute;
    margin-bottom: 0;
  }
}
