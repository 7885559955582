.masonry {
  display: flex;
  margin-left: -1.25em;
  width: auto;
}
.masonry_column {
  padding-left: 1.25em;
}

.masonry_column > div {
  margin-bottom: 1.25em;
}

.masonry {
  &.masonry-service-selection {
    .ant-card {
      min-height: 0px;
      max-height: 400px;
      padding-bottom: 1rem;
    }
  }

  .ant-card {
    min-height: 250px;
    max-height: 400px;
  }
}

.ant-card {
  display: flex;
  flex-direction: column;
}
.ant-card-actions {
  margin-top: auto;
}

.ant-card-body {
  overflow: auto;
}
