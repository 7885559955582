.statistic-block {
  .ant-statistic-title {
    text-transform: uppercase;
  }

  .ant-statistic-content {
    font-weight: 600;
  }

  &--company {
    .ant-statistic {
      margin-bottom: 0.75rem;

      &-title {
        margin: 0;
      }
    }
  }
}
